import axios from 'axios'
import router from '@/router'
//import { Message,MessageBox  } from 'element-ui'
//let requestList = new Set() // 存储请求url
function Axios(url,method,data){
    axios.interceptors.request.use(config=> {  // request handle
        if(localStorage.userinfo){
            let userinfo = JSON.parse(localStorage.userinfo)
            config.headers['Authorization'] = userinfo? ("bearer " + userinfo.token):null
        }
        //console.log(config)
        // 利用cancelToken 取消当次请求
        return config
    }, error=>{
        return Promise.reject(error)
    });
    axios.interceptors.response.use(response=> {     // response handle
        //console.log(response)
        const code = response.data.code, message = response.data.message
        switch(code){
            case 2000:
                Promise.resolve(response)
                // MessageBox('Token已过期，请重新登陆！', '提示', {
                //     confirmButtonText: '确定',cancelButtonText: '取消', type: 'warning',center:true
                // }).then(() => {
                //            //message({ type: 'success', message: '!'});
                //     router.push("/login")
                // }).catch(() => {
                //     router.push("/login")
                // })
                break
            case 4000:  case 40001:  case 41000:     //用户没有登陆、用户被锁定、参数验证失败
                //Message.error(message)
                Promise.reject(message)
                break
            case 40002:    //认证失败
                           // Message.error('账号或密码错误')
                Promise.reject('账号或密码错误')
                break
            case 40003:    //授权失败
                //Message.error(message)
                Promise.reject('没有权限')
                break
            case 40004:    //登陆过期
                //Message.error(message)
                Promise.reject('登陆过期')
                break
            case 5004:  //用户token失效
                //Message.error(message)
                Promise.reject(response.data.$message)
                localStorage.removeItem("userinfo")
                router.push("/login")
                break
            case 70000:    //业务错误
                //Message.error('操作失败...')
                Promise.reject('XXXX')
                break
            default:
            //Message.error(message)
            //Promise.reject(message)
        }
        return response
    }, error => {
        console.log(error)
        return Promise.reject('连接超时...')
    })
    return axios({
        method: method||'get',
        baseURL:"http://120.78.137.148:8083",//http://192.168.31.105:8089  http://192.168.31.16:7100",
        timeout: 5000,
        url:url,
        data:data||null
    })
}
export default Axios

