<template>
  <div id="app" :class="{'mobile': isMobile}">
    <div class="header" id="header">
      <div class="container header-main">
        <div class="flex flex-middle header-main-warp">
          <div class="header-left pointer" @click="skipHome">
            <img alt="logo" src="/img/bly.png" class="v-middle logo">
            八里云
          </div>
          <ul class="flex-item menu">
            <li class="menu-item" @click="skipHome">首页</li>
            <li v-for="(item,index) in classify" :key="index" :class="{'menu-item':true, active:index == active}">
                {{item.name}}<img src="/img/icon-arrow-down.png" alt="下拉" class="m-l-5 v-middle">
                <div class="drop-menu">
                    <div v-for="(col,num) in item.children" :key="num" @click="skipList(col.id,index,num)">{{col.name}}</div>
                </div>
            </li>
          </ul>
          <div>
          <!--<el-button type="primary" size="mini">登录</el-button>-->
          </div>
          <img src="/img/icon_more.png" alt="查看菜单" class="m-l-16 menu-view" @click.stop="switchPop()">
        </div>
      </div>
      <!--      <div class="container" >-->
      <!--        <ul class="flex mobile-menu">-->
      <!--          <li v-for="(item,index) in menu" :key="index" :class="{'flex-item menu-item':true, active:index == active}" @click="menuSwitch(index)"> {{item.name}} </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <!-- 移动端 导航菜单-->
      <div class="pop-menu" v-show="isShowPop" ref="pop">
        <div class="sub-title sub-bg pop-menu-title">精选分类</div>
        <ul class="mobile-popmenu">
          <li @click="skipHome">
            <div class="pop-menu-item">
              <div class="flex-item">首页</div>
            </div>
          </li>
          <li v-for="(item,index) in classify" :key="index">
            <div class="flex pop-menu-item" @click="switchPopSub(index)">
              <div class="flex-item">{{item.name}}</div>
              <img alt="arrow" :src="'/img/arrow-' + (item.isShowSub?'up':'down') + '.png'" class="m-l-5">
            </div>
            <div class="pop-menu-item pop-submenu" v-for="(col,num) in item.children" :key="num" @click="skipList(col.id,index,num)" v-show="item.isShowSub">{{col.name}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pack">
      <router-view></router-view>
    </div>
    <div class="sub-bg slogan">「 彼此成就，世界更远 」 </div>
    <div class="foot">
      <div class="container foot-menu">
        <div class="flex-item flex">
          <ul v-for="(item,index) in footMenu" :key="index" class="flex-item">
            <li class="sub-title">{{item.name}}</li>
            <li class="m-t-10" v-for="(col,num) in item.sub" :key="num">
              <div v-if="col === '联系我们'">
                <a href="http://www.8milecloud.com/#/article/16">联系我们</a>
              </div>
              <div v-if="col === '了解八里云'">
                <a href="http://www.8milecloud.com/#/article/6">了解八里云</a>
              </div>
              <div v-if="col === '智慧消防平台'">
                <a href="https://www.nbiotyun.com">智慧消防平台</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright">© 2009-2020 8MileCloud.com 版权所有 ICP证：粤ICP备2021015890号</div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      isShowPop:false,
      active:0,
      menu:[{name:"云产品",location:"cloud",distance:0},{name:"解决方案",location:"solution",distance:0},{name:"八里云大学",location:"edution",distance:0},{name:"了解八里云",location:"bali",distance:0}],
      classify:[],
      nav:101,
      sub:101,
      wuzhen:['/img/wuzhen.png','/img/wuzhen.png','/img/wuzhen.png','/img/wuzhen.png'],
     footMenu:[
       {name:"支持与服务",sub:["联系我们"]},
       //{name:"资源",sub:["云产品","解决方案","云教育","了解八里云"]}
       {name:"资源",sub:["了解八里云","智慧消防平台"]}
       // {name:"支持与服务",sub:["联系我们","新手学堂","自助工具","运维与管理"]},
       // {name:"账户管理",sub:["联系我们","新手学堂","自助工具","运维与管理"]},
       // {name:"联系我们",sub:["联系我们","新手学堂","自助工具","运维与管理","咨询与设计"]},
       //{icon:"/img/bali-gzh.png"},

     ],
    }
  },
  mounted(){
    let _this = this,nums = localStorage.getItem("active")
    let activeArr = nums?nums.split(","):[101,101]
    this.nav = activeArr[0]
    this.sub = activeArr[1]
    this.queryData()
    document.addEventListener('click',(e)=>{
      //console.log(_this.$refs.pop,_this.$refs.pop.contains(e.target))
      if(!_this.$refs.pop.contains(e.target)){  //这里 _this 代表组件，this代表document
        _this.isShowPop = false
      }
    })
    this.$router.push({name:"list",params:{id:3} })
  },
  methods:{
    skipHome(){
        //this.$router.push("/")
        this.$router.push({name:"list",params:{id:3} })
        // localStorage.setItem("active",[101,101])
        this.isShowPop = false
    },
    hidePanel (e) {
      if (!this.$refs.projectButton.contains(e.target)) {
        this.visible = false
        this.hide()
      }
    },
    queryData(){ //获取所有菜单、子菜单项
      let _this = this
      this.$fetch.allType().then(res=>{
        //console.log(res.data.data)
        if(res.data.code ==2000){
          _this.classify = res.data.data
          for(let i in _this.classify){
            _this.$set(_this.classify[i],"isShowSub",false)
          }
        }else{
          this.$message.error('获取失败')
        }
      }).catch(err=>{
        this.$message.error(err,'获取失败')
      })
    },
    switchPop(){ //打开菜单弹出层
      this.isShowPop = !this.isShowPop
    },
    show() {
      this.visible = true
      document.addEventListener('click', this.hidePanel, false)
    },
    hide () {
      this.visible = false
      document.removeEventListener('click', this.hidePanel, false)
    },
    // hidePanel (e) {
    //   if (!this.$refs.searchMain.contains(e.target)) {
    //     this.hide()
    //   }
    // },
    switchPopSub(index){ //点击一级菜单，显示对应的二级菜单
      this.nav = index
      this.classify[index].isShowSub = !this.classify[index].isShowSub
    },
    menuSwitch(index){  //页面主菜单
      //this.active = index
     this.$router.push({name:"/list",params:index})
    },
    skipList(id,index,subIndex){
     // console.log(id,index,subIndex)
      this.nav = index
      this.sub = subIndex
      localStorage.setItem("active",[index,subIndex])
      this.$router.push({name:"list",params:{id:id} })
      //this.$router.push("/list/" + id)
      this.isShowPop = false
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/usual.scss';
  .header{
    background:#fff;
    position: relative;
      .logo{ width:60px}
    .header-main-warp{ padding: 15px 0; }
    .header-left{
      font-size: 24px;
      font-weight: bold;
    }
    .menu,.mobile-menu{
      margin-left: 100px;
      display: flex;
      .menu-item{
        color: #000;
        font-size: 16px;
        padding:12px 30px 12px 12px;
        cursor: pointer;
        text-decoration: none;
        position:relative;
        .drop-menu{
          position: absolute;
          top: 100%;
          left: 0;
          color: #000;
          border-top: 1px solid #F2F2F2;
          box-shadow: 0px 3px 3px 3px #F2F2F2;
          background: #fff;
          padding: 16px 12px 12px 16px;
          display: none;
          white-space: nowrap;
          min-width: 100%;
          font-size: 14px;
          line-height: 36px;
          div:hover{
            color:#4CC5CB;
          }
        }
        &:hover{
          color:#4CC5CB;
          .drop-menu{ display:block }
        }
      }
    }
    .mobile-menu{
      margin-left: 0;
      display: none;
      padding: 15px 0;
      .menu-item{
        text-align: center;
        padding-right:0;
      }
    }
    .pop-menu,.menu-view{  display: none }
  }
  .mobile{
    .header-left{  flex-grow: 1 }
    .menu-view{ display: block}
    .menu{ display:none }
    .mobile-popmenu{ min-height: 150px}
    .pop-menu{
      top: 100%;
      right: 0;
      position: absolute;
      background: #fff;
      border:1px solid #F2F2F2;
      width: 100%;
      .pop-menu-title{
        background: #51CCDC;
        height: 48px;
        line-height: 24px;
        padding: 12px;
        box-sizing: border-box;
      }
      .pop-menu-item{
        padding: 12px;
        border-bottom: 1px solid #F2F2F2;
      }
      .pop-submenu{
        text-indent: 1rem;
        /*display: none;*/
      }
    }
  }
</style>
