<template>
    <div class="left-block m-r-15">
        <div class="sub-bg tac cloud-title">精选分类</div>
        <ul class="cloud-menu">
            <li v-for="(item,index) in classify" :key="index" class="cloud-menu-item">
                <div class="flex flex-middle" v-if="item.parentId=='0'">{{item.name}} <img alt="arrow" src="/img/arrow.png" class="m-l-5"></div>
                <div class="text-grey cloud-menu-sub m-t-10" v-show="item.children && item.children.length">
                    <span v-for="(col,num) in item.children" :key="num" @click="skipList(col.id,index,num)" :class="{'sub-menu':true,'active':index== nav && num == sub}">{{col.name}}
                        <span class="split-bar">|</span>
                    </span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                classify:[
                    {title:"云产品",sub:["SaaS 类企培 e-learning"]},
                    {title:"解决方案",sub:["行业解决方案","通用解决方案"]},
                    {title:"云教育",sub:["八里云大学","K12教育"]},
                    {title:"了解八里云",sub:["八里云科技","八里云教育","八里智库","八里创投"]}
                ],
                nav:101,
                sub:101
            }
        },
        mounted(){
            this.setSubActive()
            this.queryData()
        },
        watch: {
            $route(to) {
                if(to.name=="list") {
                    this.setSubActive()
                    //console.log("--- menu ---",localStorage.getItem("active"))
                }
            }
        },
        methods:{
            queryData(){
                let _this = this
                this.$fetch.allType().then(res=>{
                    //console.log(res.data.data)
                    if(res.data.code ==2000){
                        _this.classify = res.data.data
                    }else{
                        this.$message.error('获取失败');
                    }
                }).catch(err=>{
                    this.$message.error('获取失败');
                    console.log(err)
                })
            },
            setSubActive(){
                let nums = localStorage.getItem("active")
                let activeArr = nums?nums.split(","):[101,101]  //let activeArr = localStorage.getItem("active").split(",")
                this.nav = activeArr[0]
                this.sub = activeArr[1]
            },
            skipList(id,index,subIndex){
                //console.log(id,index,subIndex)
                this.nav = index
                this.sub = subIndex
                localStorage.setItem("active",[index,subIndex])
                this.$router.push({name:"list",params:{id:id} })
                //this.$router.push("/list/" + id)
            },
        }
    }
</script>
<style lang="scss" scoped>
    .cloud-menu-sub{
        .sub-menu{ cursor: pointer}
        &>.sub-menu:hover,&>.sub-menu.active{ color: #4CC5CB }
    }
</style>
