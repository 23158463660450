import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

console.log(navigator.userAgent)
let terminal = 0
if(/Android|webOS|iPhone|iPod|iPad|Windows Phone|BlackBerry|SymbianOS/i.test(navigator.userAgent)){
  terminal  = 1
}
Vue.prototype.isMobile = terminal
//接口文档
import * as apiObj from '@/api/api.js'
Vue.prototype.$fetch = apiObj
//全局组件 - Menu
import Menu from '@/components/Menu.vue'
Vue.component("Menu", Menu)
//自定义指令图片加载错误
Vue.directive('default-img',async function (el, binding) {
  let url = binding.value,available = await imgIsAvailable(url)
  if (available) {
    el.setAttribute('src', url);
  }
})
//检测图片是否存在
const imgIsAvailable = function(url) {
  return new Promise((resolve) => {
    let img = new Image()
    img.onload = function () {
      if (this.complete == true){
        resolve(true)
        img = null
      }
    }
    img.onerror = function () {
      resolve(false)
      img = null
    }
    img.src = url
  })
}

import Element from 'element-ui'
Vue.use(Element)
import 'element-ui/lib/theme-chalk/index.css'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
