import fetch from './axios'
/* APP-内容  http://192.168.31.105:8089/swagger-ui/index.html#/APP-%E5%86%85%E5%AE%B9/allTypeUsingGET */
//所有分类
export const allType = ()=> fetch('/app/general/ge/non/allType')
//内容详情
export const info = (id)=> fetch('/app/general/ge/non/info/' + id)
// 搜索内容
export const infoSearch = (data) => fetch('/app/general/ge/non/search','POST',data)


