import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const Home = () => import(/* webpackChunkName: 'FuncPages' */ '@/components/Home'),         //首页
    List = () => import(/* webpackChunkName: 'FuncPages' */ '@/components/List'),           //列表页
    Article = () => import(/* webpackChunkName: 'FuncPages' */ '@/components/Article')      //文章页
    //View = () => import(/* webpackChunkName: 'FuncPages' */ '@/components/common/View')     //视图

const router = new Router({
    mode: 'hash',
    //base: process.env.BASE_URL,  //:location  history
    routes:[
        {
            path:"/",
            alias:"/home",
            name:"home",
            component:Home

        },
        {
            path:"/list/:id",///:id
            name:"list",
            component:List
        },
        {
            path:"/article/:id",
            name:"article",
            component:Article
        },

    ]
})
export default router
